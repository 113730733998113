<template>
  <div>
    <div class="jiaotong">
      <div class="jiaotong_1" id="map">
<!--        <img src="@/assets/img/di.jpg" cla width="100%" height="100%" alt="">-->
      </div>
      <div class="jiaotong_2">
        <div class="jiaotong_2_1" style="background: #f0f0f0;border-radius: 5px">
          <div class="jiaotong_2_1_1">无锡江南古运河旅游度假区</div>
          <div class="jiaotong_2_1_2">
            <div class="jiaotong_2_1_2_1">开放时间:</div>
            <div class="jiaotong_2_1_2_2">
              <div>13:00-20:00(淡季:11月1日至3月31日)</div>
              <div class="gao"></div>
              <div>13:00-20:30(旺季:4月1日至10月31日)</div>
              <div class="gao"></div>
              <div>9:00-20:00(淡季:法定节假日)</div>
              <div class="gao"></div>
              <div>0:00-24:00(全天开放,内部各小景点)</div>
              <div style="margin-top: 20px;height: 1px;background: #ddd"></div>
            </div>
          </div>
          <div class="jiaotong_2_1_2">
            <div class="jiaotong_2_1_2_1">游船班次:</div>
            <div class="jiaotong_2_1_2_2">
              <div>日游:14:30;夜游:19:00,20:15</div>
              <div class="gao"></div>
              <div>节假日及周六,周日增加:10:00(运河公园发船时间)</div>
              <div class="gao"></div>
              <div>日游:14:45;夜游:19:15,20:30</div>
              <div class="gao"></div>
              <div>节假日及周六,周日增加:10:15(火车站码头发船时间)</div>
              <div style="margin-top: 20px; height: 1px;background: #ddd "></div>
            </div>
          </div>
          <div class="jiaotong_2_1_2">
            <div class="jiaotong_2_1_2_1">地址:</div>
            <div class="jiaotong_2_1_2_2">
              <div>江苏省无锡市梁溪区向阳路38号</div>
            </div>
          </div>
          <div class="jiaotong_2_1_2">
            <div class="jiaotong_2_1_2_1">客服热线:</div>
            <div class="jiaotong_2_1_2_2">
              <div>400-xxx-xxxx</div>
            </div>
          </div>
        </div>
        <div class="jiaotong_2_2">
          <div class="jiaotong_2_2_1">
            <div class="jiaotong_2_2_1_top">
              <div class="jiaotong_2_2_1_top_img">
                <img src="@/assets/img/cat.png" width="100%" height="100%" alt="">
              </div>
              <div class="jiaotong_2_2_1_g">公交路线</div>
            </div>
            <div class="jiaotong_2_2_2">乘坐公交29路、52路、70路、73路、81路、88路至永安桥下</div>
          </div>
          <div class="zhan"></div>
          <div class="jiaotong_2_2_1">
            <div class="jiaotong_2_2_1_top">
              <div class="jiaotong_2_2_1_top_img">
                <img src="@/assets/img/BZ11.png" width="100%" height="100%" alt="">
              </div>
              <div class="jiaotong_2_2_1_g">自驾路线</div>
            </div>
            <div class="jiaotong_2_2_2">搜索“无锡古运河”导航“古运河”即达</div>
<!--            <img src="../../assets/img/local.png" alt="">-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TMap from "../../utils/initMap";
export default {
  name: "jiaotong",
  data(){
    return {
      tMap:null
    }
  },
  mounted() {
    this.initMap()
  },
  methods:{
    initMap(){
      //定义地图中心点坐标
      TMap.init().then((TMap) => {
        this.TXMap = TMap;
        this.tMap = new TMap.Map("map", {
          center: new TMap.LatLng(31.566088,120.30797), //设置地图中心点坐标
          zoom: 15, //设置地图缩放级别
          viewMode: "2D",
        });
        console.log(123)
        new TMap.MultiMarker({
          map:this.tMap,
          styles: {  // 点标记样式
            "myStyle": new TMap.MarkerStyle({
              "width": 20,
              "height": 28,
              "src": '/img/local.png',
              "anchor": { x: 10, y: 10 },
              "opacity": 1
            })
          },
          geometries: [{
              "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
              "styleId": 'myStyle',  //指定样式id
              "position": new TMap.LatLng(31.566088,120.30797),  //点标记坐标位置
              "properties": {//自定义属性
                "title": "marker1"
              }
            }],
        })
      });

    },
  }
}
</script>

<style scoped lang="less">
.gao{
  height: 10px;
}
.zhan{
  margin-top: 100px;
}
.jiaotong{
  width: 100%;
  height: 820px;
  display: flex;
  .jiaotong_1{
    width: 960px;
    height: 800px;
  }
  .jiaotong_2 {
    width: 514px;
    height: 100%;
    margin-left: 40px;
    margin-top: 10px;
    font-family: "JDZY" !important;
    .jiaotong_2_1 {
      width: 514px;
      //border: #808080 1px dashed;
      padding: 10px;
      box-sizing: border-box;
      .jiaotong_2_1_1 {
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 20px;
        margin-left: 11px;
      }
      .jiaotong_2_1_2 {
        display: flex;
        width: 514px;
        margin-top: 20px;
        .jiaotong_2_1_2_1 {
          width: 80px;
          color: #808080;
          text-align: right;
          font-family: "JDZY" !important;
        }

        .jiaotong_2_1_2_2 {
          margin-left: 10px;
          font-family: "JDZY" !important;
        }
      }
    }
  }
    .jiaotong_2_2{
      margin-top: 69px;
      //border: #808080 1px dashed;
      padding: 10px;
      box-sizing: border-box;
      margin-left: 10px;
      .jiaotong_2_2_1{
        .jiaotong_2_2_1_top{
          display: flex;
          .jiaotong_2_2_1_top_img{
            width: 40px;
          }
          .jiaotong_2_2_1_g{
            font-size: 20px;
            font-weight: 700;
            margin-top: 4px;
            margin-left: 20px;
            font-family: "JDZY" !important;
          }
        }
        .jiaotong_2_2_2{
          margin-top: 20px;
          font-family: "JDZY" !important;
        }
      }
    }
  }

</style>
